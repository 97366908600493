function completeUrl(urlList) {
    if (urlList === null) return;
    return urlList.map(URL => require('@/assets/plugins/carousel/images/' + URL));
}

const imageNames = ['fishbowl.png', 'raycaster2.png', 'platformer.png', 'raycaster.png'];
let imageURLs = completeUrl(imageNames);
let navScroll = 3;
let navImages = getNewNavImages(navScroll);
let collection;
let upArrow;
let downArrow;

function getNewNavImages(newIdx) {
    const previousIdx =  newIdx - 1 > -1 ? newIdx - 1 : imageNames.length - 1;
    const nextIdx =  newIdx + 1 <= imageNames.length - 1 ? newIdx + 1 : 0;
    let tempList =  completeUrl([imageNames[previousIdx], imageNames[newIdx], imageNames[nextIdx]]);
    
    return [
        {
            idxNumber: previousIdx,
            url: tempList[0],
        },
        {
            idxNumber: newIdx,
            url: tempList[1],
        },
        {
            idxNumber: nextIdx,
            url: tempList[2],
        },
    
    ];
    
}



function setNavImages() {
    for (let i = 0; i < collection.length; i++) {
        collection[i].src = navImages[i].url;
    }
}

function setAllImages() {
    navImages = getNewNavImages(navScroll);
    setNavImages();
    mainImage.src = imageURLs[navScroll];
}

let imagesCallBacks = [];



function initNavImages(i) {
    navScroll = navImages[i].idxNumber;
    setAllImages();
        
}

function upArrowCallBack() {
    navScroll =  navScroll < imageNames.length - 1 ? navScroll += 1 : 0;
    setAllImages();
}

function downArrowCallBack() {
    navScroll =  navScroll != 0 ? navScroll -= 1 : imageNames.length - 1;
    setAllImages();
}

let mainImage; 

function cleanUp() {
    upArrow.removeEventListener('click', upArrowCallBack);
    downArrow.removeEventListener('click', downArrowCallBack);
    for (let i = 0; i < collection.length; i++) { 
        collection[i].removeEventListener("click", imagesCallBacks[i])
    }
}

export default function () {
    collection = document.getElementsByClassName("nav-image");
    upArrow = document.getElementsByClassName("arrow up")[0];
    downArrow = document.getElementsByClassName("arrow down")[0];
    upArrow.addEventListener("click", upArrowCallBack)
    downArrow.addEventListener("click", downArrowCallBack);
    setNavImages();
    for (let i = 0; i < collection.length; i++) { 
        const selectedImage = collection[i];
        imagesCallBacks.push(() => initNavImages(i));
        selectedImage.addEventListener("click", imagesCallBacks[i])
    }
    mainImage = document.getElementById("main-image");
    mainImage.src = imageURLs[navScroll];
}

export {cleanUp, upArrow}