<template>
<div>
    <ProjectPageTemplate
        projectTitle="GALLERY"
        :imageSource="require('@/assets/images/projectLinks/carouselLink.png')"
        shortDescription="SUITABLE FOR TOUCH SCREEN - Using vanilla CSS without Vue elements to allow for a main image with a carousel to be displayed. Press arrows to scroll through or click/press on an image in the carousel to display."
        longDescription= "Images are loaded through a JavaScript file. Event listeners are added to each image in the carousel and to the arrows to check for clicks.  Images class elements are stored in an array as are the images URLs. On clicks the main image number value is changed and for the images to be shown in the carousel are calculated. The source of these elements is set the correct item in the URL in the array, to change the image being displayed."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <carousel></carousel>
    </template>
    </ProjectPageTemplate>
</div>
</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import Carousel from '@/components/CarouselComp.vue';
import techLinks from '@/data/techLinks.js'
export default {
    name: 'carouselPage',
    components: {
        ProjectPageTemplate,
        Carousel,
    },
    data() {
        return {
            techLinksData: [
                techLinks.html, 
                techLinks.javascript,
                techLinks.css
            ],
        }
    }
}
</script>